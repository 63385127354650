import React from 'react';
import PropTypes from 'prop-types';

const Buffer = ({ src }) => {
    return (
        <React.Fragment>
            <div className="buffer-container">
                <img className="buffer-image" src={src} alt="image" />
            </div>
        </React.Fragment>
    );
};

Buffer.propTypes = {
    src: PropTypes.string,
};

export default Buffer;
