import React from 'react';

const Header = () => {
    return (
        <React.Fragment>
            <div className="header-container">
                <p className="title-name">Tudor Bungardean</p>

                <p className="subtitle">Entrepreneur - Software Developer - Writer</p>

                <div className="subtitle-list-item-container">
                    <span className="subtitle-list-item">- Entrepreneur -</span>
                    <span className="subtitle-list-item">- Software Developer -</span>
                    <span className="subtitle-list-item">- Writer -</span>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Header;
