import React from 'react';

const SocialMedia = () => {
    const socialMediaAccounts = [
        {
            platform: 'Github',
            url: 'https://github.com/TudorBNG',
        },
        {
            platform: 'Instagram',
            url: 'https://www.instagram.com/tudor.bungardean/',
        },
        {
            platform: 'LinkedIn',
            url: 'https://www.linkedin.com/in/tudor-bungardean-25a506187/',
        },
        {
            platform: 'Twitter',
            url: 'https://twitter.com/TudorBNG',
        },
    ];

    const openUrl = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <React.Fragment>
            <div className="social-media-header-container">
                <p className="social-media-header">Social Media</p>
            </div>
            <div className="options">
                {socialMediaAccounts.map(({ platform, url }, index) => (
                    <button key={index} className="option-container github option" onClick={() => openUrl(url)}>
                        {platform}
                    </button>
                ))}
            </div>
        </React.Fragment>
    );
};

export default SocialMedia;
