import React from 'react';
import Buffer from '../buffer/buffer';

import profilePicture from './../../assets/images/profile-picture.jpg';
import bufferImage from './../../assets/images/buffer-image.jpg';
import SocialMedia from '../social-media/social-media';

const PageBody = () => {
    return (
        <React.Fragment>
            <div className="page-core">
                <div className="body-container">
                    <div className="left-container">
                        <div className="profile-picture-container">
                            <img className="profile-picture" src={profilePicture} alt="profile picture" />
                        </div>
                    </div>
                    <div className="right-container">
                        <div className="description-container">
                            <p className="description"></p>
                            <p className="description-paragraph">
                                - Founder of platforms and mobile apps which have the tech industry and social media as main focus point.{' '}
                            </p>

                            <p className="description-paragraph">To be continued...</p>
                        </div>
                    </div>
                </div>
                <Buffer src={bufferImage} />
                <SocialMedia />
            </div>
        </React.Fragment>
    );
};

export default PageBody;
