import React from 'react';
import Header from '../header/header';
import PageBody from '../page-body/page-body';

const MainPage = () => {
    return (
        <React.Fragment>
            <div className="website-container">
                <Header />
                <PageBody />
            </div>
        </React.Fragment>
    );
};

export default MainPage;
